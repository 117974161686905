import FiltersCheckInComponent from "@/components/retail/checkIn/filtersCheckIn/FiltersCheckIn.component";
import AddCustomerComponent from "@/components/retail/inStoreCustomers/AddCustomer/AddCustomer.component";
import TablePatientSelectComponent from "@/components/retail/pos/pointOfSale/posCustomerList/tablePatientSelect/TablePatientSelect.component";
import { Customer } from "@/interfaces/customer";
import { customerLimits, RuleConfig } from "@/interfaces/limits";
import { Rule } from "@/interfaces/location";
import { Order } from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { CustomerPosTableMetadata } from "@/metadata/customer";
import { customerService } from "@/services/customer.service";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { Callback } from "@/types/types";
import {
  TableComponent,
  TableHeader,
  TableImageComponent,
  TableLimitComponent,
  TablePagination,
  TableStatusCheckinComponent,
  TableTextComponent
} from "helix-vue-components";
import isEmpty from "lodash/isEmpty";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PosCustomerList.template.vue";
const namespace: string = "CustomerModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableImageComponent,
    TableTextComponent,
    TableLimitComponent,
    "patient-select": TablePatientSelectComponent,
    FiltersCheckInComponent,
    TableStatusCheckinComponent,
    AddCustomerComponent
  },
  filters: {
    fixName(name: string) {
      return name.length > 15 ? name.substr(0, 15) + "..." : name;
    }
  }
})
export default class PosCustomerListComponent extends Vue {
  public get isCallIn(): boolean {
    return (
      !!this.order.pre_order &&
      this.order.pre_order.confirmed_at === null &&
      this.order.pre_order.source === "CALL_IN"
    );
  }

  get displayCustomer() {
    if (this.currentCustomer) {
      return this.currentCustomer.purchasingBy || this.currentCustomer;
    }
    return null;
  }

  get currentPatient() {
    if (this.isCaregiver) {
      return this.currentCustomer;
    }
    return null;
  }

  get getCustomers() {
    let checkDailyLimit;
    this.customers.map(data => {
      if (data.customer_journals_count) {
        data.notes = String(data.customer_journals_count);
        data.shows_checkin_note = true;
      } else {
        data.notes = null;
        data.shows_checkin_note = false;
      }
      data.limits = data.limits!.filter(objects => typeof objects === "object");
      checkDailyLimit = data.limits!.map(items => {
        // @ts-ignore
        return items.name === "Daily Single Overall Limit";
      });
    });
    if (checkDailyLimit) {
      this.customers.map(data => {
        data.limits!.reverse();
      });
    }
    return this.customers.reverse();
  }

  get displayLimits() {
    return (
      (!isEmpty(this.customerLimits) &&
        Array.isArray(this.customerLimits) &&
        this.customerLimits.filter(data => typeof data === "object")) ||
      (this.currentCustomer &&
        Array.isArray(this.currentCustomer.limits) &&
        this.currentCustomer.limits!.filter(
          data => typeof data === "object"
        )) ||
      []
    ).reverse();
  }

  public showCustomersList: boolean = false;
  @Prop({ default: false })
  public isCaregiver!: boolean;

  @Prop({ required: true })
  public currentCustomer!: Customer | null;

  @Getter("customers", { namespace })
  public customers!: Customer[];
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Action("loadCustomers", { namespace })
  public getCustomersAction!: Callback;
  @Action("loadMemberLevels", { namespace: "MemberLevelModule" })
  public loadMemberLevels!: Callback;
  @Getter("customerLimits", { namespace: "OrderModule" })
  public customerLimits!: RuleConfig[];
  @Action("configService", { namespace })
  public configService!: Callback;
  @Action("clearCustomerList", { namespace })
  public clearCustomerList!: Callback;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  public paginate = customerService.paginationAction();
  @Prop({ required: true })
  public order!: Order;
  @Action("newOrder", { namespace: "OrderModule" })
  public createNewOrder!: Callback;
  @Action("setCustomer", { namespace })
  public setCurrentCustomer!: Callback;
  public filteresSelected = [];
  protected headers: TableHeader[] = CustomerPosTableMetadata;

  public async openCustomersList() {
    if (this.isCallIn && !(await this.removeCurrentCallInOrder())) {
      return;
    }

    this.configService("pos");
    this.showCustomersList = !this.showCustomersList;
    if (this.showCustomersList) {
      await customerService.filterInStore();
    }
  }

  public async openCallInModal(): Promise<void> {
    if (this.isCallIn && !(await this.removeCurrentCallInOrder())) {
      return;
    }

    this.$modals
      .load(
        AddCustomerComponent,
        {
          size: "fit"
        },
        {
          labels: {
            title: this.$t("pos_call_in.create_call_in").toString(),
            newButton: this.$t("pos_call_in.for_new_customer").toString(),
            existingButton: this.$t(
              "pos_call_in.for_existing_customer"
            ).toString()
          },
          routesName: {
            add: "pos-customers-add",
            list: "pos-customers-list"
          }
        }
      )
      .then(() => {
        this.configService("call-in");
      })
      .catch(() => {
        // Nothing to do on close
      });
  }

  public setNewCustomer(customer: Customer) {
    this.$emit("customerSelected", customer);
    this.showCustomersList = false;
  }

  public getName(customer: Customer) {
    return `${customer.first_name} ${customer.last_name}`;
  }

  protected created() {
    this.clearCustomerList();
    this.loadMemberLevels();
    this.headers.forEach((el: TableHeader) => {
      if (el.fieldComponent === "TableLimitComponent") {
        el.options = {
          showRemaining:
            this.retailSettings.sales_limit_view === "REMAINING" ? true : false
        };
      }
    });
  }

  // Added Filters query in pagination
  protected storeFilterSelected(data: []) {
    this.filteresSelected = data;
  }

  protected changePagination(pagination: TablePagination) {
    this.paginate({
      filteresSelected: this.filteresSelected,
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage
    });
  }

  private async removeCurrentCallInOrder(): Promise<boolean> {
    try {
      const message = {
        title: this.$t("pos_call_in.call_in_pre_order").toString(),
        acceptButton: "yes",
        cancelButton: "no",
        text: this.$t("pos_call_in.not_complete_call_in").toString()
      };
      const confirmation = await this.$modals.loadConfirmation(message, {
        positionX: "center",
        size: "small"
      });

      if (confirmation) {
        const preOrder = await orderFulfillmentService.deletePreorder(
          this.order.pre_order!.uid
        );

        if (!!preOrder) {
          this.setCurrentCustomer(null);
          await this.createNewOrder();
          // this.loadSaleProductsEvent();
          return true;
        }
      }
    } catch (error) {
      // Nothing to do
    }

    return false;
  }
}
